const React = require("react")
// const Layout = require("./src/components/layout")
// // Adds a class name to the body element
// exports.onRenderBody = ({ setPreBodyComponents }, pluginOptions) => {
//   setPreBodyComponents([
//     <script type="text/javascript">
//       {`
//         var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
//         (function(){
//         var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
//         s1.async=true;
//         s1.src='https://embed.tawk.to/60cc9b7965b7290ac636a7b7/1f8fjul3e';
//         s1.charset='UTF-8';
//         s1.setAttribute('crossorigin','*');
//         s0.parentNode.insertBefore(s1,s0);
//         })();`}
//     </script>,
//   ])
// }
// // Wraps every page in a component
// // exports.wrapPageElement = ({ element, props }) => {
// //   return <Layout {...props}>{element}</Layout>
// // }

// exports.onInitialClientRender = () => {
//   var Tawk_API = Tawk_API || {},
//     Tawk_LoadStart = new Date()
//   ;(function () {
//     var s1 = document.createElement("script"),
//       s0 = document.getElementsByTagName("script")[0]
//     s1.async = true
//     s1.src = "https://embed.tawk.to/60cc9b7965b7290ac636a7b7/1f8fjul3e"
//     s1.charset = "UTF-8"
//     s1.setAttribute("crossorigin", "*")
//     s0.parentNode.insertBefore(s1, s0)
//   })()

//   //PASTE THE SNIPPET CODE HERE AND REMOVE THE 'BEGINNING OF and END OF TAWK.TO SNIPPET COMMENTS'
// }
    // <script type="text/javascript">
    //   {`
    //     var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    //     (function(){
    //     var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    //     s1.async=true;
    //     s1.src='https://embed.tawk.to/60cc9b7965b7290ac636a7b7/1f8fjul3e';
    //     s1.charset='UTF-8';
    //     s1.setAttribute('crossorigin','*');
    //     s0.parentNode.insertBefore(s1,s0);
    //     })();`}
    // </script>